import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter, OnChanges, ElementRef, HostListener } from '@angular/core';
import { STComponentBase } from '@shared/osharp/components/st-component-base';
import { STComponent } from '@delon/abc';
import { OsharpSTColumn } from '@shared/osharp/services/alain.types';
import { FilterRule, FilterOperate, AjaxResult } from '@shared/osharp/osharp.model';
import { _HttpClient } from '@delon/theme';
import { NzInputDirective, NzMessageService } from 'ng-zorro-antd';
import { Console } from 'console';
import { OsharpService } from '@shared/osharp/services/osharp.service';

@Component({
  selector: 'osharp-topic-modal',
  templateUrl: './topic-modal.component.html',
  styles: []
})

export class TopicModalComponent implements OnInit {
  @Input() visible: boolean;
  @Input() topicId: number;
  @Input() subjectId: number;
  @Input() title: string;

  @Output() submited: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public http: _HttpClient, public msg: NzMessageService) {
  }
  ngOnInit() {
  }
  ngOnChanges() {
    this.getTopic();
  }


  topicDto: Topic = {
    id: 0,
    subjectId: 0,
    title: '',
    topicType: 0,
    describe: '',
    picture: '',
    topicOptions: []
  };
  topicOptions: TopicOption[] = [];


  getTopic() {
    if (this.topicId > 0) {
      let url = `api/admin/subject/GetTopicById?id=${this.topicId}`;
      this.http.get<AjaxResult>(url).subscribe(result => {
        if (result.succeed) {
          this.topicDto = result.data;
          this.topicOptions = result.data.topicOptions;
          this.index = this.topicOptions.length;
        }
      });
    } else {
      this.topicDto = new Topic();
      this.topicDto.id = 0;
      this.topicDto.subjectId = this.subjectId;
      this.topicOptions = [];
      this.index = 0;
    }
  }


  index: number = 0;
  editId: string | '';
  acceptString = 'image/png, image/jpeg,image/gif,image/jpg';
  uploadUrl = 'api/common/uploadimage';


  // #region 表格编辑

  @ViewChild(NzInputDirective, { static: false, read: ElementRef }) inputElement: ElementRef;
  @HostListener('window:click', ['$event'])
  handleClick(e: MouseEvent): void { }

  addRow(): void {
    this.index++;
    this.topicOptions = [
      ...this.topicOptions,
      {
        id: `${new Date().getTime()}`,//使用时间戳
        index: this.index,
        content: '',
        score: 0
      }
    ];
  }

  deleteRow(id: string): void {
    this.topicOptions = this.topicOptions.filter(d => d.id !== id);
    this.topicOptions.forEach((item, i) => {
      item.index = i + 1;
    });
    this.index = this.topicOptions.length;
  }

  startEdit(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editId = id;
  }

  // #endregion



  uploadHandle(res): void {
    if (res.file.status === 'done') {
      this.topicDto.picture = res.file.response.data;
    } else if (res.file.status === 'error') {
      this.msg.error(`${res.file.name} file upload failed.`);
    }
  }
  rowUploadHandle(res, id: string): void {
    if (res.file.status === 'done') {
      this.topicOptions.filter(r => r.id == id)[0].content = res.file.response.data;
    } else if (res.file.status === 'error') {
      this.msg.error(`${res.file.name} file upload failed.`);
    }
  }


  closeTopic() {
    this.submited.emit(false)
  }

  saveTopic(value: any) {
    if (this.topicOptions.length < 2) {
      this.msg.warning('请添加最少两个选项');
      return;
    }
    for (let i = 0; i < this.topicOptions.length; i++) {
      if (!this.topicOptions[i].content || this.topicOptions[i].content == '') {
        this.msg.warning('请填写选项内容');
        return;
      }
    }
    value.topicOptions = this.topicOptions;
    console.log(value);


    let url = value.id > 0 ? 'api/admin/subject/UpdateTopic' : 'api/admin/subject/CreateTopic';
    this.http.post<AjaxResult>(url, value).subscribe(result => {
      if (result.succeed) {
        this.msg.success(result.message);
        this.submited.emit(true);
      } else {
        this.msg.error(result.message);
      }
    });

  }


  previewVisible=false;
  previewImage='';
  showImg(image){
    this.previewImage=image;
    this.previewVisible=!this.previewVisible;
  }
}
export class Topic {
  id: number;
  subjectId: number;
  title: string;
  topicType: number;
  describe: string;
  picture: string;
  topicOptions: Array<TopicOption>;
}
export class TopicOption {
  id: string;
  index: number;
  content: string;
  score: number;
}