import { Component, Inject, ChangeDetectionStrategy, Injector, ViewChild } from '@angular/core';
import { SettingsService, _HttpClient } from '@delon/theme';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { IdentityService } from '@shared/osharp/services/identity.service';
import { STData } from '@delon/abc';
import { AjaxResult, ChangePasswordDto } from '@shared/osharp/osharp.model';
import { OsharpService } from '@shared/osharp/services/osharp.service';
import { NzMessageService, NzModalComponent } from 'ng-zorro-antd';
import { Md5 } from 'ts-md5';
@Component({
  selector: 'header-user',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm" nz-dropdown nzPlacement="bottomRight" [nzDropdownMenu]="userMenu">
      <!-- <nz-avatar [nzSrc]="settings.user?.avatar" nzSize="small" class="mr-sm"></nz-avatar> -->
      <nz-avatar [nzSrc]="'assets/osharp/avatar.png'" nzSize="small" class="mr-sm"></nz-avatar>
      {{ settings.user['nickName'] || settings.user?.name }}
    </div>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <!-- <div nz-menu-item routerLink="/pro/account/center">
          <i nz-icon nzType="user" class="mr-sm"></i>
          {{ 'menu.account.center' | translate }}
        </div>
        <div nz-menu-item routerLink="/pro/account/settings">
          <i nz-icon nzType="setting" class="mr-sm"></i>
          {{ 'menu.account.settings' | translate }}
        </div>
        <div nz-menu-item routerLink="/exception/trigger">
          <i nz-icon nzType="close-circle" class="mr-sm"></i>
          {{ 'menu.account.trigger' | translate }}
        </div>
        <li nz-menu-divider></li> -->
        <div nz-menu-item (click)="editpwd()">
          <i nz-icon nzType="edit" class="mr-sm"></i>
          修改密码
        </div>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown-menu>

    <nz-modal #pwdmodal [nzVisible]="pvisible" nzTitle="修改密码" [nzClosable]="false" [nzFooter]="null"> 
	<form nz-form #f="ngForm"  se-container="1" gutter="24" [labelWidth]="100">
  <se label="原密码" required="required" col="1">
		<input nz-input type="password" nzRequired="true" [(ngModel)]="pwdDto.OldPassword" name="OldPassword" required style="width: 350px;" />
	  </se>
	  <se label="密码" required="required" col="1">
		<input nz-input type="password" nzRequired="true" [(ngModel)]="pwdDto.NewPassword" name="NewPassword" required style="width: 350px;" />
	  </se>
	  <se label="确认密码" required="required" col="1" required>
		<input nz-input type="password" nzRequired="true" [(ngModel)]="pwdDto.ConfirmNewPassword" name="ConfirmNewPassword" required style="width: 350px;" />
	  </se>
	</form>
	<div class="modal-footer">
	  <button nz-button type="button" (click)="npclose()">关闭</button>
	  <button nz-button type="submit" [nzType]="'primary'" (click)="savepwd(f.value)" [disabled]="!f.valid" [nzLoading]="http.loading">保存</button>
	</div>
  </nz-modal>

  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HeaderUserComponent {
  constructor(
    injector: Injector,
    public settings: SettingsService,
    private identity: IdentityService,
    public msg: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { 

    this.osharp = injector.get(OsharpService);
  }


  @ViewChild('pwdmodal', { static: false }) pwdeditModal: NzModalComponent;
  osharp: OsharpService;
  get http(): _HttpClient {
    return this.osharp.http;
  }

  logout() {
    this.identity.logout().then(res => {
      this.identity.router.navigateByUrl(this.tokenService.login_url);
    });
  }


  // #region 修改密码

  pwdDto: ChangePasswordDto = {
    UserId: 0,
    OldPassword: '',
    NewPassword: '',
    ConfirmNewPassword: '',
	};

	pvisible = false;
	editpwd() {
		this.pwdDto =new ChangePasswordDto();
		this.pvisible = true;
	}

	npclose(): void {
		this.pvisible = false;
	}
	savepwd(value: ChangePasswordDto) {
    const url = "api/admin/identity/ChangePassword";
		if(value.ConfirmNewPassword!==value.NewPassword){
			this.msg.warning("新密码与确认密码不一致");
			return;
		}
		value.OldPassword = Md5.hashStr(value.OldPassword);
		value.NewPassword = Md5.hashStr(value.NewPassword);
		value.ConfirmNewPassword = Md5.hashStr(value.ConfirmNewPassword);
     this.http.post<AjaxResult>(url, value).subscribe((res) => {
			this.osharp.ajaxResult(res, () => {
				this.pvisible = false;
        this.pwdeditModal.destroy();
			});
      
		});
	}

  // #endregion 



}
