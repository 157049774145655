import {
  Component,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { updateHostClass } from '@delon/util';
import { SettingsService, App, _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingDrawerComponent } from './setting-drawer/setting-drawer.component';
import { OsharpService } from '@shared/osharp/services/osharp.service';
import { AjaxResult } from '@shared/osharp/osharp.model';
import * as signalR from "@aspnet/signalr"; // 导入signalr
import { IdentityService } from '@shared/osharp/services/identity.service';
@Component({
  selector: 'layout-default',
  templateUrl: './default.component.html',
  styles: [`
  ._nav-item {
        position: fixed;
        right: 120px;
        z-index: 99;
        top: 16px;
      }
  `]
})
export class LayoutDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('settingHost', { read: ViewContainerRef, static: true })
  private settingHost: ViewContainerRef;
  isFetching = false;
  year: number = new Date().getFullYear();

  constructor(
    public router: Router,
    _message: NzMessageService,
    private resolver: ComponentFactoryResolver,
    private settings: SettingsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private identity: IdentityService,
    @Inject(DOCUMENT) private doc: any,
  ) {
    // scroll to top in change page
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(evt => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
        this.isFetching = false;
        if (evt instanceof NavigationError) {
          _message.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 });
        }
        return;
      }
      if (!(evt instanceof NavigationEnd || evt instanceof RouteConfigLoadEnd)) {
        return;
      }
      if (this.isFetching) {
        setTimeout(() => {
          this.isFetching = false;
        }, 100);
      }
    });
  }

  get app(): App {
    return this.settings.app;
  }

  // http请求
  osharp: OsharpService;
  get http(): _HttpClient {
    return this.osharp.http;
  }


  private setClass() {
    const { el, doc, renderer, settings } = this;
    const layout = settings.layout;
    updateHostClass(el.nativeElement, renderer, {
      ['alain-default']: true,
      [`alain-default__fixed`]: layout.fixed,
      [`alain-default__collapsed`]: layout.collapsed,
    });

    doc.body.classList[layout.colorWeak ? 'add' : 'remove']('color-weak');
  }

  ngAfterViewInit(): void {
    if (true) {
      setTimeout(() => {
        const settingFactory = this.resolver.resolveComponentFactory(SettingDrawerComponent);
        this.settingHost.createComponent(settingFactory);
      }, 22);
    }
  }

  ngOnInit() {
    const { settings, unsubscribe$ } = this;
    settings.notify.pipe(takeUntil(unsubscribe$)).subscribe(() => this.setClass());
    this.setClass();


    //this.connnectSignalR('hubs/chathub');
    // this.initAudio();
    // setInterval(() => {
    //   this.getNotice();
    // }, 5000);
  }

  ngOnDestroy() {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }


  // #region声音设置
  public AudioElm: HTMLAudioElement;
  IsAudio = false;
  initAudio() {
    this.IsAudio = !this.IsAudio;
    this.AudioElm = document.getElementById('myAudio') as HTMLAudioElement;
    this.AudioElm.src = 'assets/ok.wav';
    if (this.IsAudio) {
      this.AudioElm.play();
      this.AudioElm.pause();
      this.AudioElm.play();
    } else {
      this.AudioElm.pause();
    }
  }

  // 通知
  getNotice() {
    this.http.get<AjaxResult>('api/Admin/Dashboard/GetNotice').subscribe((res: any) => {
      // if (res.Data.recharges > 0 || res.Data.withdraws > 0) {
      //   if (this.IsAudio) {
      //     this.AudioElm.play();
      //   }
      // }
    });
  }
  open1() {
    this.router.navigateByUrl(`/pool/recharge`);
  }



  /** 链接SignalR */
  connnectSignalR(url: string): void {
    const model = this.identity.getAccessToken();
    const token = model.token;
    const connection = new signalR.HubConnectionBuilder().withUrl(url, {
      accessTokenFactory: () =>
        token
    }).build();

    connection.on("ReceiveMessage", function (message) {
      //服务端发过来的消息
      console.log(message);
    });

    connection.start().then(() => {
      console.log('连接成功');
    }).catch(err => {
      console.log(err);
    });

    connection.onclose((err) => {
      console.info('异常重连');
      //console.log(err);
      this.connnectSignalR(url);
    });

  }
  // #endregion
}
