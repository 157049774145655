import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { STComponentBase } from '@shared/osharp/components/st-component-base';
import { STComponent, STData } from '@delon/abc';
import { OsharpSTColumn } from '@shared/osharp/services/alain.types';
import { FilterRule, FilterOperate, AjaxResult } from '@shared/osharp/osharp.model';
import { SFSchema, SFUISchema } from '@delon/form';
import { NzMessageService, NzModalComponent } from 'ng-zorro-antd';
import { NgxTinymceModule } from 'ngx-tinymce';
import { Router } from '@angular/router';
@Component({
  selector: 'osharp-answer-view',
  templateUrl: './answer-view.component.html',
  styles: []
})
export class AnswertViewComponent extends STComponentBase implements OnInit {
  @Input() subjectId: number;
  @Input() title: string;
  @Input() ReadUrl: string;

  @ViewChild('st', { static: false }) st: STComponent;
  constructor(injector: Injector, public router: Router, private msg: NzMessageService) {
    super(injector);
  }

  ngOnInit() {
    super.InitBase();
    this.ReadUrl = `api/admin/subject/ReadAnswer?subjectId=${this.subjectId}`;
  }

  ngOnChanges() {
    if (this.subjectId > 0) {
      this.ReadUrl = `api/admin/subject/ReadAnswer?subjectId=${this.subjectId}`;
      this.st.reload();
    }
  }


  protected GetSTColumns(): OsharpSTColumn[] {
    return [
      {
        title: "操作",
        fixed: "left",
        width: 65,
        buttons: [
          { text: "编辑", icon: "edit", acl: "Root.Dy.Subject.UpdateAnswer", click: (row) => this.editAnswer(row) },
          { text: "删除", icon: "delete", type: "del", acl: "Root.Dy.Subject.DeleteAnswer", click: (row) => this.deleteAnswer(row) }
        ]
      },
      // { title: '编号', index: 'id', type: 'number' },
      { title: '标题', index: 'title', ftype: 'string' },
      { title: '分数区间', index: 'minScore', ftype: 'string', format: (d) => `${d.minScore} ~ ${d.maxScore}` },
      { title: '答案', index: 'content', ftype: 'string', render: 'content' },
      { title: "创建时间", index: "createdTime", sort: true, filterable: true, type: "date" }
    ];
  }


  answerDto: any = {};
  createAnswer() {
    this.drawVisible = true;
    this.answerDto.subjectId = this.subjectId;
    this.answerDto.id= 0;

    this.answerDto.title= '';
    this.answerDto.minScore= 0;
    this.answerDto.maxScore= 0;
    this.answerDto.content='';
  }

  editAnswer(row: STData) {
    if (!row) {
      return;
    }
    this.answerDto.id= row.id;
    this.answerDto.subjectId = this.subjectId;
    this.answerDto.title= row.title;
    this.answerDto.minScore= row.minScore;
    this.answerDto.maxScore= row.maxScore;
    this.answerDto.content= row.content;
    this.drawVisible = true;
  }

  deleteAnswer(value: STData) {
    if (!value) {
      return;
    }
    let url = 'api/admin/subject/DeleteAnswer';
    this.http.post<AjaxResult>(url, [value.id]).subscribe(result => {
      this.osharp.ajaxResult(result, () => {
        this.st.reload();
      });
    });
  }



  drawVisible = false;
  closeDraw(): void {
    this.drawVisible = false;
  }


  saveAnswer(value: any): void {
    if (value.subjectId == 0) {
      this.msg.warning('请选择要添加答案的测评项目');
      return;
    }
    let url = this.answerDto.id > 0 ? 'api/admin/subject/updateanswer' : 'api/admin/subject/createanswer';
    this.http.post<AjaxResult>(url, value).subscribe(result => {
      this.osharp.ajaxResult(result, () => {
        this.st.reload();
        this.drawVisible = false;
      });
    });
  }



  uploadUrl = 'api/common/TinymceUpload';
  initConfig = {
    height: 450,
    language: 'zh_CN',
    plugins: 'link lists image axupimgs code table colorpicker textcolor wordcount contextmenu',
    toolbar:
      'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink axupimgs code | removeformat',
    images_upload_url: this.uploadUrl,
    object_resizing: false,
    image_dimensions: false,
    // 图片上传功能
    images_upload_handler: (blobInfo, success, failure) => {
      let formData;
      formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.file.name);
      this.http.post(this.uploadUrl, formData).subscribe(response => {
        if (response) {
          const url = response.data;
          success(url);
        } else {
          if (response && response.Content) {
            failure(response.Content);
          } else {
            failure('上传失败：未知错误');
          }
        }
      });
    },
    init_instance_callback: editor => {
      setTimeout(() => {
        editor.setContent(this.answerDto.content);
      }, 500);
    },
  };
}


