import { Injectable, Injector, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { zip } from "rxjs";
import { catchError } from "rxjs/operators";
import { MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN, _HttpClient, User } from "@delon/theme";
import { ACLService } from "@delon/acl";
import { TranslateService } from "@ngx-translate/core";
import { I18NService } from "../i18n/i18n.service";

import { NzIconService } from "ng-zorro-antd/icon";
import { ICONS_AUTO } from "../../../style-icons-auto";
import { ICONS } from "../../../style-icons";
import { IdentityService } from "@shared/osharp/services/identity.service";

import Heartbeats from "heartbeats";
import { AjaxResult } from "@shared/osharp/osharp.model";

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable({
	providedIn: "root"
})
export class StartupService {
	constructor(
		iconSrv: NzIconService,
		private injector: Injector,
		private menuService: MenuService,
		private translate: TranslateService,
		@Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
		private settingService: SettingsService,
		private aclService: ACLService,
		private titleService: TitleService,
		private httpClient: HttpClient
	) {
		iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
	}

	private get identity(): IdentityService {
		return this.injector.get(IdentityService);
	}

	// 心跳业务
	private viaHeartbeats() {
		const heart = Heartbeats.createHeart(1000);
		// 每5秒检测AccessToken有效期，如过期则使用RefreshToken来刷新
		heart.createEvent(5, () => {
			if (!this.identity) {
				return;
			}
			this.identity.tryRefreshToken();
		});
	}

	private viaHttp(resolve: any) {
		zip(
			this.httpClient.get("assets/osharp/app-data.json"),
			this.httpClient.get<AjaxResult>("api/admin/identity/getauthinfo"), // 获取当前用户的权限点string[]
			this.httpClient.get<AjaxResult>("api/admin/identity/profile") // 获取用户信息
		)
			.pipe(
				catchError(([ appData, authInfo, userInfo]) => {
					resolve(null);
					return [appData, authInfo, userInfo];
				})
			)
			.subscribe(
				([appData, authData, userData]) => {
					
					// Application data
					const res: any = appData;
					this.titleService.suffix = res.app.name;
					this.settingService.setApp(res.app);
					// User information: including name, avatar, email address
					if (userData.succeed) {
						const userInfo: any = userData.data;
						if (userInfo && userInfo.Account) {
							let user: User = { name: userInfo.Account, avatar: userInfo.HeadImg,nickName: userInfo.NickName };
							this.settingService.setUser(user);
						}
					}
					if (authData.succeed) {
						const authInfo: any = authData.data;
						this.aclService.setAbility(authInfo);
					}
					this.menuService.add(res.menu);
					
				},
				() => { },
				() => {
					resolve(null);
				}
			);
	}

	private viaLang(resolve: any) {
		zip(		
			this.httpClient.get(`assets/osharp/i18n/${this.i18n.defaultLang}.json`)		
		)
			.pipe(
				catchError(([langData]) => {
					resolve(null);
					return [langData];
				})
			)
			.subscribe(
				([langData]) => {					
					this.translate.setTranslation(this.i18n.defaultLang, langData);
					this.translate.setDefaultLang(this.i18n.defaultLang);
				},
				() => { },
				() => {
					resolve(null);
				}
			);
	}

	load(): Promise<any> {
		// only works with promises
		// https://github.com/angular/angular/issues/15088
		return new Promise((resolve, reject) => {
			// http
			this.viaLang(resolve);
			this.viaHttp(resolve);

			// heartbeats
			this.viaHeartbeats();
		});
	}
}
