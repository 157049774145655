import { Component } from "@angular/core";

@Component({
  selector: 'app-icons',
  template: `
<i nz-icon nzType="step-backward" nzTheme="outline"></i>
<i nz-icon nzType="step-forward" nzTheme="outline"></i>
<i nz-icon nzType="fast-backward" nzTheme="outline"></i>
<i nz-icon nzType="fast-forward" nzTheme="outline"></i>
<i nz-icon nzType="shrink" nzTheme="outline"></i>
<i nz-icon nzType="arrows-alt" nzTheme="outline"></i>
<i nz-icon nzType="down" nzTheme="outline"></i>
<i nz-icon nzType="up" nzTheme="outline"></i>
<i nz-icon nzType="left" nzTheme="outline"></i>
<i nz-icon nzType="right" nzTheme="outline"></i>
<i nz-icon nzType="caret-up" nzTheme="outline"></i>
<i nz-icon nzType="caret-down" nzTheme="outline"></i>
<i nz-icon nzType="caret-left" nzTheme="outline"></i>
<i nz-icon nzType="caret-right" nzTheme="outline"></i>
<i nz-icon nzType="up-circle" nzTheme="outline"></i>
<i nz-icon nzType="down-circle" nzTheme="outline"></i>
<i nz-icon nzType="left-circle" nzTheme="outline"></i>
<i nz-icon nzType="right-circle" nzTheme="outline"></i>
<i nz-icon nzType="double-right" nzTheme="outline"></i>
<i nz-icon nzType="double-left" nzTheme="outline"></i>
<i nz-icon nzType="vertical-left" nzTheme="outline"></i>
<i nz-icon nzType="vertical-right" nzTheme="outline"></i>
<i nz-icon nzType="vertical-align-top" nzTheme="outline"></i>
<i nz-icon nzType="vertical-align-middle" nzTheme="outline"></i>
<i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
<i nz-icon nzType="forward" nzTheme="outline"></i>
<i nz-icon nzType="backward" nzTheme="outline"></i>
<i nz-icon nzType="rollback" nzTheme="outline"></i>
<i nz-icon nzType="enter" nzTheme="outline"></i>
<i nz-icon nzType="retweet" nzTheme="outline"></i>
<i nz-icon nzType="swap" nzTheme="outline"></i>
<i nz-icon nzType="swap-left" nzTheme="outline"></i>
<i nz-icon nzType="swap-right" nzTheme="outline"></i>
<i nz-icon nzType="arrow-up" nzTheme="outline"></i>
<i nz-icon nzType="arrow-down" nzTheme="outline"></i>
<i nz-icon nzType="arrow-left" nzTheme="outline"></i>
<i nz-icon nzType="arrow-right" nzTheme="outline"></i>
<i nz-icon nzType="play-circle" nzTheme="outline"></i>
<i nz-icon nzType="up-square" nzTheme="outline"></i>
<i nz-icon nzType="down-square" nzTheme="outline"></i>
<i nz-icon nzType="left-square" nzTheme="outline"></i>
<i nz-icon nzType="right-square" nzTheme="outline"></i>
<i nz-icon nzType="login" nzTheme="outline"></i>
<i nz-icon nzType="logout" nzTheme="outline"></i>
<i nz-icon nzType="menu-fold" nzTheme="outline"></i>
<i nz-icon nzType="menu-unfold" nzTheme="outline"></i>
<i nz-icon nzType="border-bottom" nzTheme="outline"></i>
<i nz-icon nzType="border-horizontal" nzTheme="outline"></i>
<i nz-icon nzType="border-inner" nzTheme="outline"></i>
<i nz-icon nzType="border-outer" nzTheme="outline"></i>
<i nz-icon nzType="border-left" nzTheme="outline"></i>
<i nz-icon nzType="border-right" nzTheme="outline"></i>
<i nz-icon nzType="border-top" nzTheme="outline"></i>
<!-- <i nz-icon nzType="border-verticle" nzTheme="outline"></i> -->
<i nz-icon nzType="pic-center" nzTheme="outline"></i>
<i nz-icon nzType="pic-left" nzTheme="outline"></i>
<i nz-icon nzType="pic-right" nzTheme="outline"></i>
<i nz-icon nzType="radius-bottomleft" nzTheme="outline"></i>
<i nz-icon nzType="radius-bottomright" nzTheme="outline"></i>
<i nz-icon nzType="radius-upleft" nzTheme="outline"></i>
<i nz-icon nzType="radius-upright" nzTheme="outline"></i>
<i nz-icon nzType="fullscreen" nzTheme="outline"></i>
<i nz-icon nzType="fullscreen-exit" nzTheme="outline"></i>
<i nz-icon nzType="question" nzTheme="outline"></i>
<i nz-icon nzType="question-circle" nzTheme="outline"></i>
<i nz-icon nzType="plus" nzTheme="outline"></i>
<i nz-icon nzType="plus-circle" nzTheme="outline"></i>
<i nz-icon nzType="pause" nzTheme="outline"></i>
<i nz-icon nzType="pause-circle" nzTheme="outline"></i>
<i nz-icon nzType="minus" nzTheme="outline"></i>
<i nz-icon nzType="minus-circle" nzTheme="outline"></i>
<i nz-icon nzType="plus-square" nzTheme="outline"></i>
<i nz-icon nzType="minus-square" nzTheme="outline"></i>
<i nz-icon nzType="info" nzTheme="outline"></i>
<i nz-icon nzType="info-circle" nzTheme="outline"></i>
<i nz-icon nzType="exclamation" nzTheme="outline"></i>
<i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
<i nz-icon nzType="close" nzTheme="outline"></i>
<i nz-icon nzType="close-circle" nzTheme="outline"></i>
<i nz-icon nzType="close-square" nzTheme="outline"></i>
<i nz-icon nzType="check" nzTheme="outline"></i>
<i nz-icon nzType="check-circle" nzTheme="outline"></i>
<i nz-icon nzType="check-square" nzTheme="outline"></i>
<i nz-icon nzType="clock-circle" nzTheme="outline"></i>
<i nz-icon nzType="warning" nzTheme="outline"></i>
<i nz-icon nzType="issues-close" nzTheme="outline"></i>
<i nz-icon nzType="stop" nzTheme="outline"></i>
<i nz-icon nzType="edit" nzTheme="outline"></i>
<i nz-icon nzType="form" nzTheme="outline"></i>
<i nz-icon nzType="copy" nzTheme="outline"></i>
<i nz-icon nzType="scissor" nzTheme="outline"></i>
<i nz-icon nzType="delete" nzTheme="outline"></i>
<i nz-icon nzType="snippets" nzTheme="outline"></i>
<i nz-icon nzType="diff" nzTheme="outline"></i>
<i nz-icon nzType="highlight" nzTheme="outline"></i>
<i nz-icon nzType="align-center" nzTheme="outline"></i>
<i nz-icon nzType="align-left" nzTheme="outline"></i>
<i nz-icon nzType="align-right" nzTheme="outline"></i>
<i nz-icon nzType="bg-colors" nzTheme="outline"></i>
<i nz-icon nzType="bold" nzTheme="outline"></i>
<i nz-icon nzType="italic" nzTheme="outline"></i>
<i nz-icon nzType="underline" nzTheme="outline"></i>
<i nz-icon nzType="strikethrough" nzTheme="outline"></i>
<i nz-icon nzType="redo" nzTheme="outline"></i>
<i nz-icon nzType="undo" nzTheme="outline"></i>
<i nz-icon nzType="zoom-in" nzTheme="outline"></i>
<i nz-icon nzType="zoom-out" nzTheme="outline"></i>
<i nz-icon nzType="font-colors" nzTheme="outline"></i>
<i nz-icon nzType="font-size" nzTheme="outline"></i>
<i nz-icon nzType="line-height" nzTheme="outline"></i>
<i nz-icon nzType="colum-height" nzTheme="outline"></i>
<i nz-icon nzType="dash" nzTheme="outline"></i>
<i nz-icon nzType="small-dash" nzTheme="outline"></i>
<i nz-icon nzType="sort-ascending" nzTheme="outline"></i>
<i nz-icon nzType="sort-descending" nzTheme="outline"></i>
<i nz-icon nzType="drag" nzTheme="outline"></i>
<i nz-icon nzType="ordered-list" nzTheme="outline"></i>
<i nz-icon nzType="unordered-list" nzTheme="outline"></i>
<i nz-icon nzType="radius-setting" nzTheme="outline"></i>
<i nz-icon nzType="column-width" nzTheme="outline"></i>
<i nz-icon nzType="area-chart" nzTheme="outline"></i>
<i nz-icon nzType="pie-chart" nzTheme="outline"></i>
<i nz-icon nzType="bar-chart" nzTheme="outline"></i>
<i nz-icon nzType="dot-chart" nzTheme="outline"></i>
<i nz-icon nzType="line-chart" nzTheme="outline"></i>
<i nz-icon nzType="radar-chart" nzTheme="outline"></i>
<i nz-icon nzType="heat-map" nzTheme="outline"></i>
<i nz-icon nzType="fall" nzTheme="outline"></i>
<i nz-icon nzType="rise" nzTheme="outline"></i>
<i nz-icon nzType="stock" nzTheme="outline"></i>
<i nz-icon nzType="box-plot" nzTheme="outline"></i>
<i nz-icon nzType="fund" nzTheme="outline"></i>
<i nz-icon nzType="sliders" nzTheme="outline"></i>
<i nz-icon nzType="android" nzTheme="outline"></i>
<i nz-icon nzType="apple" nzTheme="outline"></i>
<i nz-icon nzType="windows" nzTheme="outline"></i>
<i nz-icon nzType="ie" nzTheme="outline"></i>
<i nz-icon nzType="chrome" nzTheme="outline"></i>
<i nz-icon nzType="github" nzTheme="outline"></i>
<i nz-icon nzType="aliwangwang" nzTheme="outline"></i>
<i nz-icon nzType="dingding" nzTheme="outline"></i>
<i nz-icon nzType="weibo-square" nzTheme="outline"></i>
<i nz-icon nzType="weibo-circle" nzTheme="outline"></i>
<i nz-icon nzType="taobao-circle" nzTheme="outline"></i>
<i nz-icon nzType="html5" nzTheme="outline"></i>
<i nz-icon nzType="weibo" nzTheme="outline"></i>
<i nz-icon nzType="twitter" nzTheme="outline"></i>
<i nz-icon nzType="wechat" nzTheme="outline"></i>
<i nz-icon nzType="youtube" nzTheme="outline"></i>
<i nz-icon nzType="alipay-circle" nzTheme="outline"></i>
<i nz-icon nzType="taobao" nzTheme="outline"></i>
<i nz-icon nzType="skype" nzTheme="outline"></i>
<i nz-icon nzType="qq" nzTheme="outline"></i>
<i nz-icon nzType="medium-workmark" nzTheme="outline"></i>
<i nz-icon nzType="gitlab" nzTheme="outline"></i>
<i nz-icon nzType="medium" nzTheme="outline"></i>
<i nz-icon nzType="linkedin" nzTheme="outline"></i>
<i nz-icon nzType="google-plus" nzTheme="outline"></i>
<i nz-icon nzType="dropbox" nzTheme="outline"></i>
<i nz-icon nzType="facebook" nzTheme="outline"></i>
<i nz-icon nzType="codepen" nzTheme="outline"></i>
<i nz-icon nzType="code-sandbox" nzTheme="outline"></i>
<i nz-icon nzType="amazon" nzTheme="outline"></i>
<i nz-icon nzType="google" nzTheme="outline"></i>
<i nz-icon nzType="codepen-circle" nzTheme="outline"></i>
<i nz-icon nzType="alipay" nzTheme="outline"></i>
<i nz-icon nzType="ant-design" nzTheme="outline"></i>
<i nz-icon nzType="ant-cloud" nzTheme="outline"></i>
<i nz-icon nzType="aliyun" nzTheme="outline"></i>
<i nz-icon nzType="zhihu" nzTheme="outline"></i>
<i nz-icon nzType="slack" nzTheme="outline"></i>
<i nz-icon nzType="slack-square" nzTheme="outline"></i>
<i nz-icon nzType="behance" nzTheme="outline"></i>
<i nz-icon nzType="behance-square" nzTheme="outline"></i>
<i nz-icon nzType="dribbble" nzTheme="outline"></i>
<i nz-icon nzType="dribbble-square" nzTheme="outline"></i>
<i nz-icon nzType="instagram" nzTheme="outline"></i>
<i nz-icon nzType="yuque" nzTheme="outline"></i>
<i nz-icon nzType="alibaba" nzTheme="outline"></i>
<i nz-icon nzType="yahoo" nzTheme="outline"></i>
<i nz-icon nzType="reddit" nzTheme="outline"></i>
<i nz-icon nzType="sketch" nzTheme="outline"></i>
<i nz-icon nzType="account-book" nzTheme="outline"></i>
<i nz-icon nzType="alert" nzTheme="outline"></i>
<i nz-icon nzType="api" nzTheme="outline"></i>
<i nz-icon nzType="appstore" nzTheme="outline"></i>
<i nz-icon nzType="audio" nzTheme="outline"></i>
<i nz-icon nzType="bank" nzTheme="outline"></i>
<i nz-icon nzType="bell" nzTheme="outline"></i>
<i nz-icon nzType="book" nzTheme="outline"></i>
<i nz-icon nzType="bug" nzTheme="outline"></i>
<i nz-icon nzType="build" nzTheme="outline"></i>
<i nz-icon nzType="bulb" nzTheme="outline"></i>
<i nz-icon nzType="calculator" nzTheme="outline"></i>
<i nz-icon nzType="calendar" nzTheme="outline"></i>
<i nz-icon nzType="car" nzTheme="outline"></i>
<i nz-icon nzType="camera" nzTheme="outline"></i>
<i nz-icon nzType="carry-out" nzTheme="outline"></i>
<i nz-icon nzType="cloud" nzTheme="outline"></i>
<i nz-icon nzType="code" nzTheme="outline"></i>
<i nz-icon nzType="compass" nzTheme="outline"></i>
<i nz-icon nzType="container" nzTheme="outline"></i>
<i nz-icon nzType="control" nzTheme="outline"></i>
<i nz-icon nzType="credit-card" nzTheme="outline"></i>
<i nz-icon nzType="contacts" nzTheme="outline"></i>
<i nz-icon nzType="customer-service" nzTheme="outline"></i>
<i nz-icon nzType="dashboard" nzTheme="outline"></i>
<i nz-icon nzType="database" nzTheme="outline"></i>
<i nz-icon nzType="dislike" nzTheme="outline"></i>
<i nz-icon nzType="environment" nzTheme="outline"></i>
<i nz-icon nzType="experiment" nzTheme="outline"></i>
<i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
<i nz-icon nzType="eye" nzTheme="outline"></i>
<i nz-icon nzType="file-add" nzTheme="outline"></i>
<i nz-icon nzType="crown" nzTheme="outline"></i>
<i nz-icon nzType="file-excel" nzTheme="outline"></i>
<i nz-icon nzType="file-image" nzTheme="outline"></i>
<i nz-icon nzType="file-pdf" nzTheme="outline"></i>
<i nz-icon nzType="file-exclamation" nzTheme="outline"></i>
<i nz-icon nzType="file-ppt" nzTheme="outline"></i>
<i nz-icon nzType="file-text" nzTheme="outline"></i>
<i nz-icon nzType="file-word" nzTheme="outline"></i>
<i nz-icon nzType="file-zip" nzTheme="outline"></i>
<i nz-icon nzType="file" nzTheme="outline"></i>
<i nz-icon nzType="file-markdown" nzTheme="outline"></i>
<i nz-icon nzType="fire" nzTheme="outline"></i>
<i nz-icon nzType="filter" nzTheme="outline"></i>
<i nz-icon nzType="flag" nzTheme="outline"></i>
<i nz-icon nzType="folder-add" nzTheme="outline"></i>
<i nz-icon nzType="folder-open" nzTheme="outline"></i>
<i nz-icon nzType="folder" nzTheme="outline"></i>
<i nz-icon nzType="frown" nzTheme="outline"></i>
<i nz-icon nzType="funnel-plot" nzTheme="outline"></i>
<i nz-icon nzType="gift" nzTheme="outline"></i>
<i nz-icon nzType="hdd" nzTheme="outline"></i>
<i nz-icon nzType="heart" nzTheme="outline"></i>
<i nz-icon nzType="home" nzTheme="outline"></i>
<i nz-icon nzType="hourglass" nzTheme="outline"></i>
<i nz-icon nzType="idcard" nzTheme="outline"></i>
<i nz-icon nzType="insurance" nzTheme="outline"></i>
<i nz-icon nzType="interaction" nzTheme="outline"></i>
<i nz-icon nzType="layout" nzTheme="outline"></i>
<i nz-icon nzType="like" nzTheme="outline"></i>
<i nz-icon nzType="mail" nzTheme="outline"></i>
<i nz-icon nzType="lock" nzTheme="outline"></i>
<i nz-icon nzType="medicine-box" nzTheme="outline"></i>
<i nz-icon nzType="meh" nzTheme="outline"></i>
<i nz-icon nzType="message" nzTheme="outline"></i>
<i nz-icon nzType="mobile" nzTheme="outline"></i>
<i nz-icon nzType="money-collect" nzTheme="outline"></i>
<i nz-icon nzType="notification" nzTheme="outline"></i>
<i nz-icon nzType="pay-circle" nzTheme="outline"></i>
<i nz-icon nzType="phone" nzTheme="outline"></i>
<i nz-icon nzType="picture" nzTheme="outline"></i>
<i nz-icon nzType="play-square" nzTheme="outline"></i>
<i nz-icon nzType="printer" nzTheme="outline"></i>
<i nz-icon nzType="profile" nzTheme="outline"></i>
<i nz-icon nzType="property-safety" nzTheme="outline"></i>
<i nz-icon nzType="project" nzTheme="outline"></i>
<i nz-icon nzType="pushpin" nzTheme="outline"></i>
<i nz-icon nzType="reconciliation" nzTheme="outline"></i>
<i nz-icon nzType="read" nzTheme="outline"></i>
<i nz-icon nzType="red-envelope" nzTheme="outline"></i>
<i nz-icon nzType="rest" nzTheme="outline"></i>
<i nz-icon nzType="rocket" nzTheme="outline"></i>
<i nz-icon nzType="safety-certificate" nzTheme="outline"></i>
<i nz-icon nzType="save" nzTheme="outline"></i>
<i nz-icon nzType="security-scan" nzTheme="outline"></i>
<i nz-icon nzType="schedule" nzTheme="outline"></i>
<i nz-icon nzType="setting" nzTheme="outline"></i>
<i nz-icon nzType="shop" nzTheme="outline"></i>
<i nz-icon nzType="shopping" nzTheme="outline"></i>
<i nz-icon nzType="skin" nzTheme="outline"></i>
<i nz-icon nzType="smile" nzTheme="outline"></i>
<i nz-icon nzType="sound" nzTheme="outline"></i>
<i nz-icon nzType="star" nzTheme="outline"></i>
<i nz-icon nzType="switcher" nzTheme="outline"></i>
<i nz-icon nzType="tag" nzTheme="outline"></i>
<i nz-icon nzType="file-unknown" nzTheme="outline"></i>
<i nz-icon nzType="tags" nzTheme="outline"></i>
<i nz-icon nzType="tablet" nzTheme="outline"></i>
<i nz-icon nzType="thunderbolt" nzTheme="outline"></i>
<i nz-icon nzType="tool" nzTheme="outline"></i>
<i nz-icon nzType="trophy" nzTheme="outline"></i>
<i nz-icon nzType="unlock" nzTheme="outline"></i>
<i nz-icon nzType="usb" nzTheme="outline"></i>
<i nz-icon nzType="video-camera" nzTheme="outline"></i>
<i nz-icon nzType="wallet" nzTheme="outline"></i>
<i nz-icon nzType="apartment" nzTheme="outline"></i>
<i nz-icon nzType="audit" nzTheme="outline"></i>
<i nz-icon nzType="barcode" nzTheme="outline"></i>
<i nz-icon nzType="bars" nzTheme="outline"></i>
<i nz-icon nzType="block" nzTheme="outline"></i>
<i nz-icon nzType="border" nzTheme="outline"></i>
<i nz-icon nzType="branches" nzTheme="outline"></i>
<i nz-icon nzType="ci" nzTheme="outline"></i>
<i nz-icon nzType="cloud-download" nzTheme="outline"></i>
<i nz-icon nzType="cloud-server" nzTheme="outline"></i>
<i nz-icon nzType="cloud-sync" nzTheme="outline"></i>
<i nz-icon nzType="cloud-upload" nzTheme="outline"></i>
<i nz-icon nzType="cluster" nzTheme="outline"></i>
<i nz-icon nzType="coffee" nzTheme="outline"></i>
<i nz-icon nzType="column-height" nzTheme="outline"></i>
<i nz-icon nzType="copyright" nzTheme="outline"></i>
<i nz-icon nzType="deployment-unit" nzTheme="outline"></i>
<i nz-icon nzType="disconnect" nzTheme="outline"></i>
<i nz-icon nzType="dollar" nzTheme="outline"></i>
<i nz-icon nzType="desktop" nzTheme="outline"></i>
<i nz-icon nzType="download" nzTheme="outline"></i>
<i nz-icon nzType="ellipsis" nzTheme="outline"></i>
<i nz-icon nzType="euro" nzTheme="outline"></i>
<i nz-icon nzType="exception" nzTheme="outline"></i>
<i nz-icon nzType="export" nzTheme="outline"></i>
<i nz-icon nzType="file-done" nzTheme="outline"></i>
<i nz-icon nzType="file-jpg" nzTheme="outline"></i>
<i nz-icon nzType="file-protect" nzTheme="outline"></i>
<i nz-icon nzType="file-search" nzTheme="outline"></i>
<i nz-icon nzType="file-sync" nzTheme="outline"></i>
<i nz-icon nzType="fork" nzTheme="outline"></i>
<i nz-icon nzType="gateway" nzTheme="outline"></i>
<i nz-icon nzType="global" nzTheme="outline"></i>
<i nz-icon nzType="gold" nzTheme="outline"></i>
<i nz-icon nzType="history" nzTheme="outline"></i>
<i nz-icon nzType="import" nzTheme="outline"></i>
<i nz-icon nzType="inbox" nzTheme="outline"></i>
<i nz-icon nzType="key" nzTheme="outline"></i>
<i nz-icon nzType="laptop" nzTheme="outline"></i>
<i nz-icon nzType="line" nzTheme="outline"></i>
<i nz-icon nzType="link" nzTheme="outline"></i>
<i nz-icon nzType="loading-3-quarters" nzTheme="outline"></i>
<i nz-icon nzType="loading" nzTheme="outline"></i>
<i nz-icon nzType="man" nzTheme="outline"></i>
<i nz-icon nzType="menu" nzTheme="outline"></i>
<i nz-icon nzType="monitor" nzTheme="outline"></i>
<i nz-icon nzType="more" nzTheme="outline"></i>
<i nz-icon nzType="number" nzTheme="outline"></i>
<i nz-icon nzType="paper-clip" nzTheme="outline"></i>
<i nz-icon nzType="percentage" nzTheme="outline"></i>
<i nz-icon nzType="pound" nzTheme="outline"></i>
<i nz-icon nzType="poweroff" nzTheme="outline"></i>
<i nz-icon nzType="pull-request" nzTheme="outline"></i>
<i nz-icon nzType="qrcode" nzTheme="outline"></i>
<i nz-icon nzType="reload" nzTheme="outline"></i>
<i nz-icon nzType="robot" nzTheme="outline"></i>
<i nz-icon nzType="safety" nzTheme="outline"></i>
<i nz-icon nzType="scan" nzTheme="outline"></i>
<i nz-icon nzType="search" nzTheme="outline"></i>
<i nz-icon nzType="select" nzTheme="outline"></i>
<i nz-icon nzType="shake" nzTheme="outline"></i>
<i nz-icon nzType="share-alt" nzTheme="outline"></i>
<i nz-icon nzType="shopping-cart" nzTheme="outline"></i>
<i nz-icon nzType="solution" nzTheme="outline"></i>
<i nz-icon nzType="sync" nzTheme="outline"></i>
<i nz-icon nzType="table" nzTheme="outline"></i>
<i nz-icon nzType="team" nzTheme="outline"></i>
<i nz-icon nzType="to-top" nzTheme="outline"></i>
<i nz-icon nzType="trademark" nzTheme="outline"></i>
<i nz-icon nzType="transaction" nzTheme="outline"></i>
<i nz-icon nzType="upload" nzTheme="outline"></i>
<i nz-icon nzType="user-add" nzTheme="outline"></i>
<i nz-icon nzType="user-delete" nzTheme="outline"></i>
<i nz-icon nzType="user" nzTheme="outline"></i>
<i nz-icon nzType="usergroup-add" nzTheme="outline"></i>
<i nz-icon nzType="usergroup-delete" nzTheme="outline"></i>
<i nz-icon nzType="wifi" nzTheme="outline"></i>
<i nz-icon nzType="woman" nzTheme="outline"></i>
  `,
})
export class IconsComponent {
  constructor() {
    // 控制台执行重新初始化
    // ng g ng-alain:plugin icon
 }
}