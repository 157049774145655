import { AlainService } from '@shared/osharp/services/alain.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OsharpService } from '@shared/osharp/services/osharp.service';
import { FilterRule, FilterOperate, PageRequest } from '@shared/osharp/osharp.model';
import { List } from 'linqts';
import { OsharpSTColumn } from '@shared/osharp/services/alain.types';
import * as moment from 'moment';
@Component({
  selector: 'osharp-ad-search',
  templateUrl: './ng-search.component.html',
  styles: [
    `
      .ant-advanced-search-form .ant-form-item {
        margin-bottom: 5px;
      }
      .ant-form-item-label {
        width: 110px;
      }
    `,
  ],
})
export class NgSearchComponent implements OnInit {
  @Input() request: PageRequest;
  @Input() columns: OsharpSTColumn[];
  @Input() shownum = 4;
  @Output() submited: EventEmitter<PageRequest> = new EventEmitter<PageRequest>();

  constructor(private osharp: OsharpService, private alain: AlainService) {}

  FilterList: any = [];

  ngOnInit() {
    this.columns = new List(this.columns)
      .Where(m => m.index && m.filterable)
      .ToArray();

    this.columns.forEach(column => {
      let rule = new FilterRule(null, null);
      rule.control = 'string';
      rule.Field = column.index + '';
      this.alain.changeFilterRuleType(rule, column);

      this.FilterList.push({
        name: column.index,
        title: column.title,
        control: rule.control,
        enums: rule.enums,
        value: '',
      });
    });
  }

  // 展开
  isCollapse = true;
  toggleCollapse(): void {
    this.isCollapse = !this.isCollapse;
  }

  // 搜索
  search() {
    let rules:FilterRule[]=[];
    // 循环判断，组合要查询的条件
    this.FilterList.forEach(item => {
      const field = item.name;
      let column = this.columns.find(m => m.index === field);
      if (!column) {
        this.osharp.warning(`指定属性${field}不存在`);
        return;
      }

      let filedValue = item.value;

      if (filedValue == null || filedValue.length < 1) {
        return true;
      }

      if (item.control === 'number' && filedValue == 0) {
        return true;
      }

      if (item.control === 'enum' && filedValue == null) {
        return true;
      }

      // 日期区间处理
      if (item.control == 'date') {
        const start = filedValue[0].toLocaleDateString();
        const end = filedValue[1].toLocaleDateString() + ' 23:59:59'; // 计算当天最后时间

        console.log('start', start);
        console.log('end', end);

        let startRule = new FilterRule(field.slice(0, 1).toUpperCase() + field.slice(1), start);
        startRule.Operate = FilterOperate.GreaterOrEqual;
        rules.push(startRule);

        let endRule = new FilterRule(field.slice(0, 1).toUpperCase() + field.slice(1), end);
        endRule.Operate = FilterOperate.LessOrEqual;
        rules.push(endRule);
      } else {
        let rule = new FilterRule(field.slice(0, 1).toUpperCase() + field.slice(1), item.value);
        rule.Operate = item.control === 'string' ? FilterOperate.Contains : FilterOperate.Equal;
        if (item.control === 'string') {
          rule.Value = item.value.trim();
        }
        rules.push(rule);
      }
    });

    this.request.PageIndex = 1;
    this.request.FilterRules = rules;
    // this.request.SortConditions=this.request.SortConditions;
    if (this.submited) {
      this.submited.emit(this.request);
    }
  }

  reset() {
    this.FilterList = [];
    this.columns.forEach(column => {
      let rule = new FilterRule(null, null);
      rule.control = 'string';
      rule.Field = column.index + '';
      this.alain.changeFilterRuleType(rule, column);
      this.FilterList.push({
        name: column.index,
        title: column.title,
        control: rule.control,
        enums: rule.enums,
        value: '',
      });
    });
   // this.request.SortConditions=[];
    this.search();
  }
}

export class NgFilterRule {
  // 过滤规则
  FilterRule: FilterRule;
  // 要查询的列定义
  OsharpSTColumn: OsharpSTColumn;
  constructor(filterRule: FilterRule, osharpSTColumn: OsharpSTColumn) {
    (this.FilterRule = filterRule), (this.OsharpSTColumn = osharpSTColumn);
  }
}
