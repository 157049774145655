import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DA_SERVICE_TOKEN, ITokenService, JWTTokenModel } from "@delon/auth";
import { SettingsService, _HttpClient, MenuService } from "@delon/theme";
import { ACLService } from "@delon/acl";
import { Observable, zip } from "rxjs";
import { ReuseTabService } from "@delon/abc";
import { CacheService } from "@delon/cache";
import {
	AjaxResult,
	AjaxResultType,
	User,
	AdResult,
	ChangePasswordDto,
	TokenDto,
	JsonWebToken,
} from "@shared/osharp/osharp.model";
import { OsharpService } from "@shared/osharp/services/osharp.service";
import { catchError } from "rxjs/operators";
import { NzMessageService } from "ng-zorro-antd";
import { Md5 } from 'ts-md5/dist/md5';
@Injectable({ providedIn: "root" })
export class IdentityService {
	constructor(
		public http: _HttpClient,
		private osharp: OsharpService,
		@Inject(DA_SERVICE_TOKEN) private tokenSrv: ITokenService,
		private cache: CacheService,
		private settingSrv: SettingsService,
		private aclSrv: ACLService,
		private reuseTabSrv: ReuseTabService,
		private menuSrv: MenuService,
		public msg: NzMessageService,
	) { }

	get user() {
		return this.settingSrv.user;
	}

	get router(): Router {
		return this.osharp.router;
	}

	/**登录 */
	token(dto: TokenDto): Promise<AjaxResult> {
		const url = "api/admin/identity/token";
		return this.http
			.post<AjaxResult>(url, dto)
			.map((result) => {
				if (result.code === AjaxResultType.Success) {
					this.loginEnd(result.data as JsonWebToken).subscribe();
				}
				return result;
			})
			.toPromise();
	}

	/**登出 */
	logout() {
		const url = "api/admin/identity/logout";
		return this.http
			.post<AjaxResult>(url, {})
			.map((res) => {
				if (res.code === AjaxResultType.Success) {
					this.loginEnd(null).subscribe();
				}
				return res;
			})
			.toPromise();
	}



	loginEnd(token: JsonWebToken) {
		// 清空路由复用信息
		this.reuseTabSrv.clear();
		// 设置Token
		this.setToken(token);
		// 刷新用户信息
		return this.refreshUser();
	}

	setToken(token: JsonWebToken) {
		if (token) {
			this.tokenSrv.set({ token: token.accessToken });
			let seconds = new Date().getTime();			
			seconds = Math.round((token.refreshTime - seconds) / 1000);
			if (seconds > 0) {
				this.cache.set("refresh_token", token.refreshToken, { expire: seconds });
			}
		} else {
			this.tokenSrv.clear();
			this.cache.remove("refresh_token");
			this.settingSrv.setUser({});
		}
	}

	getAccessToken(): JWTTokenModel {
		const accessToken = this.tokenSrv.get<JWTTokenModel>(JWTTokenModel);
		return accessToken;
	}

	getRefreshToken(): string {
		const refreshToken = this.cache.getNone<string>("refresh_token");
		return refreshToken;
	}

	/**
   * 尝试刷新AccessToken和RefreshToken，每5秒检测AccessToken有效期，如过期则使用RefreshToken来刷新
   */
	tryRefreshToken() {
		const accessToken = this.getAccessToken();
		if (accessToken && accessToken.token && accessToken.token.includes(".")) {
			const diff = Math.round(accessToken.payload.exp - new Date().getTime() / 1000);
			if (diff > 20) return;
		}
		const refreshToken = this.getRefreshToken();
		if (!refreshToken) {
			// 仅在RefreshToken失效时跳转到登录页
			if (this.router.url === "/exception/403") {
				setTimeout(() => this.router.navigateByUrl("/passport/login"));
			}
			return;
		}
		// this.refreshToken(refreshToken).subscribe();
	}

	/**
   * 使用现在的RefreshToken刷新新的AccessToken与RefreshToken
   * @param refreshToken 现有的RefreshToken
   */
	refreshToken(refreshToken: string) {
		// 使用RefreshToken刷新AccessToken
		// const dto = { RefreshToken: refreshToken, GrantType: "refresh_token" };
		return this.http.get<AjaxResult>("api/admin/identity/profile").map((result) => {
			// if (result.Type === AjaxResultType.Success) {
			// 	this.setToken(result.Data as JsonWebToken);
			// } else {
			// 	this.cache.remove("refresh_token");
			// }
			return result;
		});
	}

	/**
   * 刷新权限点、用户信息、菜单
   */
	refreshAuth() {
		zip(this.http.get<AjaxResult>("api/admin/identity/getauthinfo"), this.http.get<AjaxResult>("api/admin/identity/profile"), this.http.get("assets/osharp/app-data.json"))
			.pipe(
				catchError(([authInfo, userInfo, appData]) => {
					return [authInfo, userInfo, appData];
				})
			)
			.subscribe(([authData, userData, appData]) => {
				if (authData.succeed) {
					const authInfo: any = authData.data;
					this.aclSrv.setAbility(authInfo);
				}

				if (userData.succeed) {
					const userInfo: any = userData.Data;
					if (userInfo && userInfo.account) {
						let user: User = { name: userInfo.account, avatar: userInfo.headImg, nickName: userInfo.nickName };
						this.settingSrv.setUser(user);
					}
				}
				this.menuSrv.add(appData.menu);
			});
	}


	/** 刷新用户信息 */
	refreshUser(): Observable<User> {
		const url = "api/admin/identity/profile";
		return this.http.get<AjaxResult>(url).map((res) => {
			if (!res) {
				this.settingSrv.setUser({});
				this.aclSrv.setRole([]);
				return {};
			}
			const data=res.data;
			const user: User = {
				id: data.id,
				name: data.account,
				nickName: data.nickName,
				avatar: data.headImg,
				roles: data.roles,
				isAdmin: data.isAdmin
			};
			this.settingSrv.setUser(user);
			// 更新角色
			this.aclSrv.setRole(user.roles);
			return user;
		});
	}

}
