import { Component, OnInit, AfterViewInit, } from '@angular/core';

import * as moment from 'moment';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements AfterViewInit {
  dateFormat = 'yyyy/MM/dd';
  pickerRanges = {
    '今天': [moment().toDate(), moment().toDate()],
    '昨天': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    '最近7天': [moment().subtract(6, 'days').toDate(), moment().toDate()],
    '最近30天': [moment().subtract(29, 'days').toDate(), moment().toDate()],
    '本月': [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
    '上月': [moment().subtract(1, "months").startOf("month").toDate(), moment().subtract(1, "months").endOf("month").toDate()],
    '全部': [moment("1-1-1", "MM-DD-YYYY").toDate(), moment("12-31-9999", "MM-DD-YYYY").toDate()]
  };
  constructor(private http: _HttpClient) { }

  ngAfterViewInit(): void {
    this.rangePickerChange(this.pickerRanges.最近30天);
  }

  rangePickerChange(e) {
    if (e.length === 0) {
      return;
    }
    const start = e[0].toLocaleDateString()
    const end = e[1].toLocaleDateString();
    this.summaryData(start, end);
  }


  summaries: Summary[] = [];
  /** 统计数据 */
  summaryData(start, end) {
    // const url = `api/admin/dashboard/SummaryData?start=${start}&end=${end}`;
    // this.http.get(url).subscribe((res: any) => {
    //   if (!res) {
    //     return;
    //   }
    //   this.summaries = [];
    //   this.summaries.push({ data: `${res.users.ValidCount} / ${res.users.TotalCount}`, text: '用户：已激活 / 总计', bgColor: 'bg-primary' });
    //   this.summaries.push({ data: `${res.roles.AdminCount} / ${res.roles.TotalCount}`, text: '角色：管理 / 总计', bgColor: 'bg-success' });
    //   this.summaries.push({ data: `${res.modules.SiteCount} / ${res.modules.AdminCount} / ${res.modules.TotalCount}`, text: '模块：前台 / 管理 / 总计', bgColor: 'bg-orange' });
    //   this.summaries.push({ data: `${res.functions.ControllerCount} / ${res.functions.TotalCount}`, text: '功能：控制器 / 总计', bgColor: 'bg-magenta' });
    // });

    this.summaries.push({ data: `${100} / ${56}`, text: '用户：已激活 / 总计',icon:'smile', bgColor: 'bg-primary' });
    this.summaries.push({ data: `${2} / ${10}`, text: '角色：管理 / 总计',icon:'smile', bgColor: 'bg-success' });
    this.summaries.push({ data: `${12} / ${13} / ${15}`, text: '模块：前台 / 管理 / 总计',icon:'smile', bgColor: 'bg-orange' });
    this.summaries.push({ data: `${100} / ${123}`, text: '功能：控制器 / 总计',icon:'smile', bgColor: 'bg-magenta' });
  }
}

export class Summary {
  data: string;
  text: string;
  icon:string;
  bgColor = 'bg-primary';
}
