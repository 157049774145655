/*
* Automatically generated by 'ng g ng-alain:plugin icon'
* @see https://ng-alain.com/cli/plugin#icon
*/

import {
  AccountBookOutline,
  AlertOutline,
  AlibabaOutline,
  AlignCenterOutline,
  AlignLeftOutline,
  AlignRightOutline,
  AlipayCircleOutline,
  AlipayOutline,
  AliwangwangOutline,
  AliyunOutline,
  AmazonOutline,
  AndroidOutline,
  AntCloudOutline,
  AntDesignOutline,
  ApartmentOutline,
  ApiOutline,
  AppleOutline,
  AppstoreOutline,
  AreaChartOutline,
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ArrowUpOutline,
  ArrowsAltOutline,
  AudioOutline,
  AuditOutline,
  BackwardOutline,
  BankOutline,
  BarChartOutline,
  BarcodeOutline,
  BehanceOutline,
  BehanceSquareOutline,
  BgColorsOutline,
  BlockOutline,
  BoldOutline,
  BookOutline,
  BorderBottomOutline,
  BorderHorizontalOutline,
  BorderInnerOutline,
  BorderLeftOutline,
  BorderOutline,
  BorderRightOutline,
  BorderTopOutline,
  BoxPlotOutline,
  BranchesOutline,
  BugOutline,
  BuildOutline,
  CalculatorOutline,
  CameraOutline,
  CarOutline,
  CaretLeftOutline,
  CaretRightOutline,
  CarryOutOutline,
  CheckSquareOutline,
  ChromeOutline,
  CiOutline,
  CloseSquareOutline,
  CloudDownloadOutline,
  CloudOutline,
  CloudServerOutline,
  CloudSyncOutline,
  CloudUploadOutline,
  ClusterOutline,
  CodeOutline,
  CodeSandboxOutline,
  CodepenCircleOutline,
  CodepenOutline,
  CoffeeOutline,
  ColumHeightOutline,
  ColumnHeightOutline,
  ColumnWidthOutline,
  CompassOutline,
  ContactsOutline,
  ContainerOutline,
  ControlOutline,
  CopyrightOutline,
  CreditCardOutline,
  CrownOutline,
  CustomerServiceOutline,
  DashOutline,
  DatabaseOutline,
  DeploymentUnitOutline,
  DiffOutline,
  DingdingOutline,
  DisconnectOutline,
  DislikeOutline,
  DollarOutline,
  DotChartOutline,
  DownCircleOutline,
  DownSquareOutline,
  DownloadOutline,
  DragOutline,
  DribbbleOutline,
  DribbbleSquareOutline,
  DropboxOutline,
  EnterOutline,
  EnvironmentOutline,
  EuroOutline,
  ExclamationOutline,
  ExperimentOutline,
  ExportOutline,
  EyeInvisibleOutline,
  FacebookOutline,
  FallOutline,
  FastBackwardOutline,
  FastForwardOutline,
  FileAddOutline,
  FileDoneOutline,
  FileExcelOutline,
  FileExclamationOutline,
  FileImageOutline,
  FileJpgOutline,
  FileMarkdownOutline,
  FilePdfOutline,
  FilePptOutline,
  FileProtectOutline,
  FileSearchOutline,
  FileSyncOutline,
  FileTextOutline,
  FileUnknownOutline,
  FileWordOutline,
  FileZipOutline,
  FilterOutline,
  FireOutline,
  FlagOutline,
  FolderAddOutline,
  FolderOpenOutline,
  FolderOutline,
  FontColorsOutline,
  FontSizeOutline,
  ForkOutline,
  FormOutline,
  ForwardOutline,
  FrownOutline,
  FullscreenExitOutline,
  FullscreenOutline,
  FundOutline,
  FunnelPlotOutline,
  GatewayOutline,
  GiftOutline,
  GithubOutline,
  GitlabOutline,
  GlobalOutline,
  GoldOutline,
  GoogleOutline,
  GooglePlusOutline,
  HddOutline,
  HeartOutline,
  HeatMapOutline,
  HighlightOutline,
  HistoryOutline,
  HomeOutline,
  HourglassOutline,
  Html5Outline,
  IdcardOutline,
  IeOutline,
  ImportOutline,
  InstagramOutline,
  InsuranceOutline,
  InteractionOutline,
  IssuesCloseOutline,
  ItalicOutline,
  KeyOutline,
  LaptopOutline,
  LayoutOutline,
  LeftCircleOutline,
  LeftSquareOutline,
  LikeOutline,
  LineChartOutline,
  LineHeightOutline,
  LineOutline,
  LinkedinOutline,
  Loading3QuartersOutline,
  LockOutline,
  LoginOutline,
  LogoutOutline,
  ManOutline,
  MedicineBoxOutline,
  MediumOutline,
  MediumWorkmarkOutline,
  MehOutline,
  MenuFoldOutline,
  MenuOutline,
  MenuUnfoldOutline,
  MessageOutline,
  MinusCircleOutline,
  MinusOutline,
  MinusSquareOutline,
  MobileOutline,
  MoneyCollectOutline,
  MonitorOutline,
  MoreOutline,
  NotificationOutline,
  NumberOutline,
  OrderedListOutline,
  PauseCircleOutline,
  PauseOutline,
  PayCircleOutline,
  PercentageOutline,
  PhoneOutline,
  PicCenterOutline,
  PicLeftOutline,
  PicRightOutline,
  PictureOutline,
  PieChartOutline,
  PlayCircleOutline,
  PlaySquareOutline,
  PlusCircleOutline,
  PlusSquareOutline,
  PoundOutline,
  PoweroffOutline,
  PrinterOutline,
  ProjectOutline,
  PropertySafetyOutline,
  PullRequestOutline,
  PushpinOutline,
  QqOutline,
  QrcodeOutline,
  QuestionOutline,
  RadarChartOutline,
  RadiusBottomleftOutline,
  RadiusBottomrightOutline,
  RadiusSettingOutline,
  RadiusUpleftOutline,
  RadiusUprightOutline,
  ReadOutline,
  ReconciliationOutline,
  RedEnvelopeOutline,
  RedditOutline,
  RedoOutline,
  ReloadOutline,
  RestOutline,
  RetweetOutline,
  RightCircleOutline,
  RightSquareOutline,
  RiseOutline,
  RobotOutline,
  RocketOutline,
  RollbackOutline,
  SafetyCertificateOutline,
  SaveOutline,
  ScanOutline,
  ScheduleOutline,
  ScissorOutline,
  SelectOutline,
  SettingOutline,
  ShakeOutline,
  ShareAltOutline,
  ShopOutline,
  ShoppingCartOutline,
  ShoppingOutline,
  ShrinkOutline,
  SketchOutline,
  SkinOutline,
  SkypeOutline,
  SlackOutline,
  SlackSquareOutline,
  SlidersOutline,
  SmallDashOutline,
  SmileOutline,
  SnippetsOutline,
  SolutionOutline,
  SortAscendingOutline,
  SortDescendingOutline,
  SoundOutline,
  StarOutline,
  StepBackwardOutline,
  StepForwardOutline,
  StockOutline,
  StopOutline,
  StrikethroughOutline,
  SwapLeftOutline,
  SwapOutline,
  SwapRightOutline,
  SwitcherOutline,
  SyncOutline,
  TableOutline,
  TabletOutline,
  TagOutline,
  TagsOutline,
  TaobaoCircleOutline,
  TaobaoOutline,
  TeamOutline,
  ThunderboltOutline,
  ToTopOutline,
  ToolOutline,
  TrademarkOutline,
  TransactionOutline,
  TrophyOutline,
  TwitterOutline,
  UnderlineOutline,
  UndoOutline,
  UnlockOutline,
  UnorderedListOutline,
  UpCircleOutline,
  UpSquareOutline,
  UsbOutline,
  UserAddOutline,
  UserDeleteOutline,
  UserOutline,
  UsergroupAddOutline,
  UsergroupDeleteOutline,
  VerticalAlignBottomOutline,
  VerticalAlignMiddleOutline,
  VerticalAlignTopOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  VideoCameraOutline,
  WalletOutline,
  WarningOutline,
  WechatOutline,
  WeiboCircleOutline,
  WeiboOutline,
  WeiboSquareOutline,
  WifiOutline,
  WindowsOutline,
  WomanOutline,
  YahooOutline,
  YoutubeOutline,
  YuqueOutline,
  ZhihuOutline,
  ZoomInOutline,
  ZoomOutOutline
} from '@ant-design/icons-angular/icons';

export const ICONS_AUTO = [
  AccountBookOutline,
  AlertOutline,
  AlibabaOutline,
  AlignCenterOutline,
  AlignLeftOutline,
  AlignRightOutline,
  AlipayCircleOutline,
  AlipayOutline,
  AliwangwangOutline,
  AliyunOutline,
  AmazonOutline,
  AndroidOutline,
  AntCloudOutline,
  AntDesignOutline,
  ApartmentOutline,
  ApiOutline,
  AppleOutline,
  AppstoreOutline,
  AreaChartOutline,
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ArrowUpOutline,
  ArrowsAltOutline,
  AudioOutline,
  AuditOutline,
  BackwardOutline,
  BankOutline,
  BarChartOutline,
  BarcodeOutline,
  BehanceOutline,
  BehanceSquareOutline,
  BgColorsOutline,
  BlockOutline,
  BoldOutline,
  BookOutline,
  BorderBottomOutline,
  BorderHorizontalOutline,
  BorderInnerOutline,
  BorderLeftOutline,
  BorderOutline,
  BorderRightOutline,
  BorderTopOutline,
  BoxPlotOutline,
  BranchesOutline,
  BugOutline,
  BuildOutline,
  CalculatorOutline,
  CameraOutline,
  CarOutline,
  CaretLeftOutline,
  CaretRightOutline,
  CarryOutOutline,
  CheckSquareOutline,
  ChromeOutline,
  CiOutline,
  CloseSquareOutline,
  CloudDownloadOutline,
  CloudOutline,
  CloudServerOutline,
  CloudSyncOutline,
  CloudUploadOutline,
  ClusterOutline,
  CodeOutline,
  CodeSandboxOutline,
  CodepenCircleOutline,
  CodepenOutline,
  CoffeeOutline,
  ColumHeightOutline,
  ColumnHeightOutline,
  ColumnWidthOutline,
  CompassOutline,
  ContactsOutline,
  ContainerOutline,
  ControlOutline,
  CopyrightOutline,
  CreditCardOutline,
  CrownOutline,
  CustomerServiceOutline,
  DashOutline,
  DatabaseOutline,
  DeploymentUnitOutline,
  DiffOutline,
  DingdingOutline,
  DisconnectOutline,
  DislikeOutline,
  DollarOutline,
  DotChartOutline,
  DownCircleOutline,
  DownSquareOutline,
  DownloadOutline,
  DragOutline,
  DribbbleOutline,
  DribbbleSquareOutline,
  DropboxOutline,
  EnterOutline,
  EnvironmentOutline,
  EuroOutline,
  ExclamationOutline,
  ExperimentOutline,
  ExportOutline,
  EyeInvisibleOutline,
  FacebookOutline,
  FallOutline,
  FastBackwardOutline,
  FastForwardOutline,
  FileAddOutline,
  FileDoneOutline,
  FileExcelOutline,
  FileExclamationOutline,
  FileImageOutline,
  FileJpgOutline,
  FileMarkdownOutline,
  FilePdfOutline,
  FilePptOutline,
  FileProtectOutline,
  FileSearchOutline,
  FileSyncOutline,
  FileTextOutline,
  FileUnknownOutline,
  FileWordOutline,
  FileZipOutline,
  FilterOutline,
  FireOutline,
  FlagOutline,
  FolderAddOutline,
  FolderOpenOutline,
  FolderOutline,
  FontColorsOutline,
  FontSizeOutline,
  ForkOutline,
  FormOutline,
  ForwardOutline,
  FrownOutline,
  FullscreenExitOutline,
  FullscreenOutline,
  FundOutline,
  FunnelPlotOutline,
  GatewayOutline,
  GiftOutline,
  GithubOutline,
  GitlabOutline,
  GlobalOutline,
  GoldOutline,
  GoogleOutline,
  GooglePlusOutline,
  HddOutline,
  HeartOutline,
  HeatMapOutline,
  HighlightOutline,
  HistoryOutline,
  HomeOutline,
  HourglassOutline,
  Html5Outline,
  IdcardOutline,
  IeOutline,
  ImportOutline,
  InstagramOutline,
  InsuranceOutline,
  InteractionOutline,
  IssuesCloseOutline,
  ItalicOutline,
  KeyOutline,
  LaptopOutline,
  LayoutOutline,
  LeftCircleOutline,
  LeftSquareOutline,
  LikeOutline,
  LineChartOutline,
  LineHeightOutline,
  LineOutline,
  LinkedinOutline,
  Loading3QuartersOutline,
  LockOutline,
  LoginOutline,
  LogoutOutline,
  ManOutline,
  MedicineBoxOutline,
  MediumOutline,
  MediumWorkmarkOutline,
  MehOutline,
  MenuFoldOutline,
  MenuOutline,
  MenuUnfoldOutline,
  MessageOutline,
  MinusCircleOutline,
  MinusOutline,
  MinusSquareOutline,
  MobileOutline,
  MoneyCollectOutline,
  MonitorOutline,
  MoreOutline,
  NotificationOutline,
  NumberOutline,
  OrderedListOutline,
  PauseCircleOutline,
  PauseOutline,
  PayCircleOutline,
  PercentageOutline,
  PhoneOutline,
  PicCenterOutline,
  PicLeftOutline,
  PicRightOutline,
  PictureOutline,
  PieChartOutline,
  PlayCircleOutline,
  PlaySquareOutline,
  PlusCircleOutline,
  PlusSquareOutline,
  PoundOutline,
  PoweroffOutline,
  PrinterOutline,
  ProjectOutline,
  PropertySafetyOutline,
  PullRequestOutline,
  PushpinOutline,
  QqOutline,
  QrcodeOutline,
  QuestionOutline,
  RadarChartOutline,
  RadiusBottomleftOutline,
  RadiusBottomrightOutline,
  RadiusSettingOutline,
  RadiusUpleftOutline,
  RadiusUprightOutline,
  ReadOutline,
  ReconciliationOutline,
  RedEnvelopeOutline,
  RedditOutline,
  RedoOutline,
  ReloadOutline,
  RestOutline,
  RetweetOutline,
  RightCircleOutline,
  RightSquareOutline,
  RiseOutline,
  RobotOutline,
  RocketOutline,
  RollbackOutline,
  SafetyCertificateOutline,
  SaveOutline,
  ScanOutline,
  ScheduleOutline,
  ScissorOutline,
  SelectOutline,
  SettingOutline,
  ShakeOutline,
  ShareAltOutline,
  ShopOutline,
  ShoppingCartOutline,
  ShoppingOutline,
  ShrinkOutline,
  SketchOutline,
  SkinOutline,
  SkypeOutline,
  SlackOutline,
  SlackSquareOutline,
  SlidersOutline,
  SmallDashOutline,
  SmileOutline,
  SnippetsOutline,
  SolutionOutline,
  SortAscendingOutline,
  SortDescendingOutline,
  SoundOutline,
  StarOutline,
  StepBackwardOutline,
  StepForwardOutline,
  StockOutline,
  StopOutline,
  StrikethroughOutline,
  SwapLeftOutline,
  SwapOutline,
  SwapRightOutline,
  SwitcherOutline,
  SyncOutline,
  TableOutline,
  TabletOutline,
  TagOutline,
  TagsOutline,
  TaobaoCircleOutline,
  TaobaoOutline,
  TeamOutline,
  ThunderboltOutline,
  ToTopOutline,
  ToolOutline,
  TrademarkOutline,
  TransactionOutline,
  TrophyOutline,
  TwitterOutline,
  UnderlineOutline,
  UndoOutline,
  UnlockOutline,
  UnorderedListOutline,
  UpCircleOutline,
  UpSquareOutline,
  UsbOutline,
  UserAddOutline,
  UserDeleteOutline,
  UserOutline,
  UsergroupAddOutline,
  UsergroupDeleteOutline,
  VerticalAlignBottomOutline,
  VerticalAlignMiddleOutline,
  VerticalAlignTopOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  VideoCameraOutline,
  WalletOutline,
  WarningOutline,
  WechatOutline,
  WeiboCircleOutline,
  WeiboOutline,
  WeiboSquareOutline,
  WifiOutline,
  WindowsOutline,
  WomanOutline,
  YahooOutline,
  YoutubeOutline,
  YuqueOutline,
  ZhihuOutline,
  ZoomInOutline,
  ZoomOutOutline
];
