import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
@Component({
  selector: 'osharp-img-modal',
  templateUrl: './img-modal.component.html',
  styles: [],
})
export class ImgModalComponent implements OnInit {
  @Input() previewVisible: boolean;
  @Input() previewImage: string;
  @Output() submited: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}
  ngOnInit() {}

  cancel() {
    this.submited.emit(false);
  }
}
