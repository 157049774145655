import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { STComponentBase } from '@shared/osharp/components/st-component-base';
import { STColumnTag, STComponent, STData } from '@delon/abc';
import { OsharpSTColumn } from '@shared/osharp/services/alain.types';
import { FilterRule, FilterOperate, AjaxResult } from '@shared/osharp/osharp.model';
import { SFSchema, SFUISchema } from '@delon/form';
import { NzModalComponent } from 'ng-zorro-antd';
import { NgxTinymceModule } from 'ngx-tinymce';
import { Router } from '@angular/router';
@Component({
  selector: 'osharp-topic-view',
  templateUrl: './topic-view.component.html',
  styles: []
})
export class TopicViewComponent extends STComponentBase implements OnInit {
  @Input() subjectId: number;
  @Input() title: string;
  @Input() ReadUrl: string;
  @ViewChild('st', { static: false }) st: STComponent;
  constructor(injector: Injector, public router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.InitBase();
    this.ReadUrl = `api/admin/subject/ReadTopic?subjectId=${this.subjectId}`;
  }
  ngOnChanges() {
    if (this.subjectId > 0) {
      this.ReadUrl = `api/admin/subject/ReadTopic?subjectId=${this.subjectId}`;
      this.st.reload();
    }
  }


  protected GetSTColumns(): OsharpSTColumn[] {
    return [
      {
        title: "操作",
        fixed: "left",
        width: 65,
        buttons: [
          { text: "编辑", icon: "edit", acl: "Root.Dy.Subject.UpdateAnswer", click: (row) => this.editTopic(row) },
          { text: "删除", icon: "delete", type: "del", acl: "Root.Dy.Subject.DeleteAnswer", click: (row) => this.deleteTopic(row) }
        ]
      },
      // { title: '编号', index: 'id', type: 'number' },
      { title: '题目', index: 'title', ftype: 'string' },
      { title: '类型', index: 'topicType', type: 'tag', tag: this.TopicTypeTags },
      { title: '选项数量', index: 'optionNum', type: 'number' },
      { title: '附加图片', index: 'picture', type: 'img', render: 'picture' },
      { title: '描述', index: 'describe', ftype: 'string' },
    ];
  }

  TopicTypeTags: STColumnTag = {
    0: { text: '选项', color: 'blue' },
    1: { text: '图片', color: 'green' },
  };


  topicId: number = 0;
  topicVisible = false;
  closeTopic(isReload) {
    this.topicVisible = false;
    if (isReload) {
      this.st.reload();
    }
  }
  createTopic() {
    this.topicId = 0;
    this.topicVisible = true;
  }

  editTopic(row: STData) {
    if (!row) return;
    this.topicId = row.id;
    this.topicVisible = true;
  }

  deleteTopic(row: STData) {
    if (!row) {
      return;
    }
    let url = 'api/admin/subject/DeleteTopic';
    this.http.post<AjaxResult>(url, [row.id]).subscribe(result => {
      this.osharp.ajaxResult(result, () => {
        this.st.reload();
      });
    });
  }
}